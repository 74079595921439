import { Box, CircularProgress } from "@mui/material";
import "./App.css";

function App() {
  return (
    <div className="App">
      <h2 className="HeaderName">PayU</h2>
      <span>Comming Soon...</span>
      <br /><br />
      <Box> 
        <CircularProgress />
      </Box>
    </div>
  );
}

export default App;
